import React from "react";
import { Container, Flex, Message, Text } from "basis";

import { branch, commit } from "../core/config";

const Info = () => (
  <Container height="96">
    <Flex height="100%" placeItems="center" padding="6 8">
      <Message
        severity="info-or-minor"
        bg="secondary.lightBlue.t25"
        title="Deployed from"
      >
        <Text>Branch: {branch}</Text>
        <Text>Commit: {commit}</Text>
      </Message>
    </Flex>
  </Container>
);

export default Info;
